import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/contact.scss"
import {BurgerMenuIconAnimation, HeaderLayout} from "../constants/layoutStyle"
import BurgerMenu from "../components/burgerMenu";
import BranchPageLayout from "../components/branchPageLayout";

export default function Home({ data }) {
  const contactData =
    data.allContentfulContact.edges[0].node.text.childMarkdownRemark.html
  return (
    <>
      <SEO title="相談する" description="お問合せについて" />
      <BranchPageLayout>
        <BurgerMenu iconAnination={BurgerMenuIconAnimation.Visible} />
        <div className="contact-main">
          <main className="main-area">
            <div
              dangerouslySetInnerHTML={{ __html: contactData }}
              className="contact-textarea"
            />
          </main>
        </div>
      </BranchPageLayout>
    </>
  )
}
export const query = graphql`
  query {
    allContentfulContact(filter: { node_locale: { eq: "ja" } }) {
      edges {
        node {
          text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
